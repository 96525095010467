<template>
  <div class="page" style="box-sizing: border-box; margin-top: 46px;">
    <van-nav-bar title="用户列表" fixed left-arrow @click-left="$router.back()" right-text="添加" @click-right="$router.push('/user/addUser')"></van-nav-bar>
    <div style="height: calc(100% - 46px);overflow: scroll">
      <van-cell v-for="user in userList" :key="user.id" :title="user.username" :label="user.state" is-link center>
        <template #default>
<!--          <span style="margin-right: 10px">{{ user.username }}</span>-->
          <van-tag style="margin: 0 2px" :type="item.code === 'admin' ? 'success' : 'primary'" plain v-for="item in user.roleList" :key="item.code">{{ item.name }}</van-tag>
        </template>
      </van-cell>
      <div style="color: gray;font-size: 14px;display: flex;justify-content: center;align-items: center; height: 40px">没有了</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userList",
  data() {
    return {
      userList: []
    }
  },
  created() {
    this.loadUser()
  },
  methods: {
    async loadUser() {
      this.$toast.loading()
      let res = await this.$http.get('userInfo')
      this.$toast.clear()
      if (res.code === 200) {
        this.userList = res.data
      }
    }
  }
}
</script>

<style scoped>

</style>
